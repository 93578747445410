// pages/about.js

import React from "react";

const About = () => {
    return (
    <div className= "About" style={{backgroundColor: '#282c34' }}>
            <header className="App-header">
        <h1>
          About Us
        </h1>
      </header>
    </div>
    );
};

export default About;