// pages/about.js

import React, { useState, useEffect } from 'react';

const CheckpointList = () => {
    return (
    <div className= "CheckpointList" style={{backgroundColor: '#282c34' }}>
            <header className="App-header">
        <h1>
          CheckpointList
        </h1>
        <p>Discord Bot</p>
        <p id= "text"></p>
      </header>
    </div>
    );
};

  fetch(
  "https://docs.google.com/spreadsheets/d/1JmV-28EMiC9q8hnSbtpTprtVjDcrPKPgE_UDuJQIQ8c/edit?gid=822929884#gid=822929884"
)
  .then((response) => response.text())
  .then((data) => process(data));

function process(data) {
  const AllDungeons = [
		"Vesper's Host",
		"Warlord's Ruin",
		"Ghosts of the Deep", 
		"Spire of the Watcher", 
		"Duality", 
		"Grasp of Avarice", 
		"Prophecy", 
		"Pit of Heresy", 
		"Shattered Throne"]
    const AllRaids = [
      "Salvation's Edge",  
      "Crota's End", 
      "Root of Nightmares",
      "King's Fall", 
      "Vow of the Disciple", 
      "Vault of Glass", 
      "Deep Stone Crypt", 
      "Garden of Salvation", 
      "Last Wish"]
      const AllCampaigns = [
      "Final Shape",
      "Lightfall",
      "Witch Queen"
    ]
  let rows = [];
  let cells = [];
  let tempString = "";
  let skipHeader = false

  for (let i = 0; i < data.length; i++) {
    if (data[i - 3] == "/" && data[i - 2] == "t" && data[i - 1] == "r") {
      if(skipHeader){
        rows.push(cells);
        cells = [];
      }
      skipHeader = true
    }
    if (data[i - 3] == "<" && data[i - 2] == "t" && data[i - 1] == "d") {
      while (data[i] !== ">") {
        i++;
      }
      i++;
      let j = i;
      while (data[j] + data[j + 1] + data[j + 2] + data[j + 3] !== "</td") {
        tempString += data[j];
        j++;
      }
      if(tempString != "")
        cells.push(tempString);
      tempString = "";
    }
  }
  console.log(rows);
  let tempTest = ""
  let temp = ""
  //const list = document.createElement("p")
  //document.getElementById("text").appendChild(list)
  for (let i = 1 ; i < rows.length; i++){
    temp = rows[i][0]
    console.log(temp)
    //tempTest = ""
    if(typeof temp !== 'undefined' && (AllDungeons.includes(temp) || AllRaids.includes(temp) || AllCampaigns.includes(temp))){
      tempTest += rows[i][0] + ": " + rows[i][1] + "\n"
      //const t = document.createElement('BUTTON')
      //t.innerHTML = tempTest
      //t.onclick = function(){
      //  console.log("test")
      //  this.innerHTML = "Creating Queue"
      //}
      //list.appendChild(t) //having issues appending to this, seems to be loading this page and fetch when homepage is loaded
      //list.appendChild(document.createElement('br'))
    }
  }
  
  console.log(tempTest)
  //document.getElementById("text").innerText = tempTest

  
}


export default CheckpointList;